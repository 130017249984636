import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios'

// @ts-ignore: has no exported type
import { Table, Input, Button, Field, Modal, Datepicker, Select, Icon, Radio, Autocomplete, Collapse, Toast, Notification, Upload, Tooltip, Loading, Switch } from 'buefy'
import VueRouter from 'vue-router'


Vue.use(Table)
Vue.use(Input)
Vue.use(Button)
Vue.use(Field)
Vue.use(Modal)
Vue.use(Datepicker)
Vue.use(Autocomplete)
Vue.use(Select)
Vue.use(Icon)
Vue.use(Radio)
Vue.use(Collapse)
Vue.use(Toast)
Vue.use(Notification)
Vue.use(Upload)
Vue.use(Tooltip)
Vue.use(Loading)
Vue.use(Switch)

Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
