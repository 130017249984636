export const BASE_DETAILS = 'La persona no presenta ningun antecedente penal, ni investigaciones pendientes por las autoridades pertinentes.'
export const ARGUMENT_DETAILS = 'La persona ha sido procesada y sentenciada por el sistema judicial penal por {0}. {1}. Estado actual: {2}. Numero de ficha: {3}.'
export const DEPORTED_DETAILS = 'La persona ha sido deportada.'

export const columns = [
	{
		field: 'nationalId',
		label: 'Cedula'
	},
	{
		field: 'createdAt',
		label: 'Creado'
	},
	{
		field: 'firstName',
		label: 'Primer Nombre'
	},
	{
		field: 'middleName',
		label: 'Segundo Nombre'
	},
	{
		field: 'lastName',
		label: 'Primer Appellido'
	},
	{
		field: 'secondLastName',
		label: 'Segundo Apellido'
	},
	{
		field: 'dob',
		label: 'Fecha Nacimiento'
	},
	{
		field: 'status',
		label: 'Estatus'
	},
]

export const statusMapping = {
	pending: {
		label: 'Pendiente',
		type: 'is-warning',
		icon: 'clock',
	},
	passed: {
		label: 'Aprovado',
		type: 'is-success',
		icon: 'check',
	},
	failed: {
		label: 'Fallido',
		type: 'is-danger',
		icon: 'close',
	},
}

export const estadoActual = [
	"Desestimado por Ministerio Publico: Libertad",
	"Libertad Condicional",
	"Rebeldia Ministerio Publico",
	"Libertad (Salida Menores Art. 200 Ley 14-94)",
	"Intermediacion Sin Prision: Libertad",
	"Libertad bajo fianza",
	"Fugitivo",
	"Libertad pena cumplida",
	"Cumpliendo condena"
]

export const tipoSentencia = [
	"Sometido por PN: Ficha Creada",
	"Sometido por DNCD: Ficha Creada",
	"Prisión preventiva",
	"Prisión regular",
	"Sin prisión",
	"Medida de Coercion"
]

export const razones = [
	"Robo - Ley 379-401",
	"Robo con Escalamiento - Ley 379-402",
	"Robo Asalariado - Ley 379-403",
	"Asalto - Ley 379-404",
	"Bancarrotas y estafas. - Ley 402-405",
	"Estafa contra estado - Ley 402-406",
	"Abuso de confianza. - Ley 402-407",
	"Abuso de confianza contra el Estado - Ley 402-408",
	"Falsificación de moneda - Ley 132-138",
	"Falsificacion de cheques - Ley 28-59",
	"Homicidio, asesinato, parricidio, infanticidio y envenenamiento. - Ley 295-304",
	"Heridas y golpes voluntarios no calificados homicidios - Ley 309-320",
	"Trafico de Drogas - Ley 50-88",
	"Posecion de Drogas - Ley 50-88",
	"Violencia de genero - Ley 309-1 y 309-2",
	"Manutencion de menores - Ley 136-03",
	"Porte y tenencia de armas - Ley 631-16",
	"Homicidio Involuntario - Ley 319",
	"Crímenes excusable - Ley 321-326",
	"Delitos excusables - Ley 321-327",
	"Violacion sexual a menor de edad - Ley 136-03",
	"Violacion sexual a mayor de edad - Ley 309-1, 309-2, 309-3",
	"Agresiones sexuales - Ley 24-97",
	"Secuestro y todas sus variedades - Ley 583",
	"Crímenes y delitos de Alta de Tecnología - Ley 53-07",
	"Robo de identidad - Ley 53-07",
	"Terrorismo - Ley 267-08",
	"Falsificacion de documentos - Ley 9.155 Art. 240",
	"Conducir bajo la influencia de drogas o alcohol - Ley 63-17"
]
