import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'
const Main = () => import(/* webpackChunkName: "entry-point" */ '@/views/Main.vue')
const BackgroundChecks = () => import(/* webpackChunkName: "entry-point" */ '@/views/background_checks/BackgroundChecks.vue')

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Main',
		component: Main,
		redirect: { name: 'BackgroundChecks' },
		children: [
			{
				path: 'antecedentes',
				name: 'BackgroundChecks',
				component: BackgroundChecks,
			}
		],
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '*',
		redirect: { name: 'BackgroundChecks' }
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if(to.name !== 'Login' && !localStorage.user){
		next({ name: 'Login' })
		return
	}
	next()
})

export default router
