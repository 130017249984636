import { apiUrl } from './env';
import axios from 'axios'
import router from './router';


axios.defaults.baseURL = apiUrl;

axios.interceptors.request.use(function (config) {
	const { token } = localStorage

	if(!token) return config

	const headers = {
		Authorization: `Bearer ${JSON.parse(token)}`,
	}

	return { ...config, headers }
}, function (error) {
	return Promise.reject(error)
});

axios.interceptors.response.use(function (value){
	return Promise.resolve(value)
}, function (error){
	if([403, 401].includes(error.response.status)){
		router.push({ name: 'Login' })
	}
	return Promise.reject(error)
})
