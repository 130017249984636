import Vue from 'vue'
import Vuex from 'vuex'
import { extractVuexModule, createProxy } from 'vuex-class-component'
import GlobalStore from './global'
import BackgroundChecksStore from './background-checks'

Vue.use(Vuex)

// store.vuex.ts
const store = new Vuex.Store({
	modules: {
		...extractVuexModule(GlobalStore),
		...extractVuexModule(BackgroundChecksStore),
	}
})

export default store

const Global = createProxy(store, GlobalStore)
const BackgroundChecks = createProxy(store, BackgroundChecksStore)

// Creating proxies.
export const vxm = {
	Global: Global,
	BackgroundChecks: BackgroundChecks,
}

export {
	Global,
	BackgroundChecks,
}

