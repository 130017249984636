
import { Vue, Component } from 'vue-property-decorator'
import { Global } from '../store'

@Component
export default class Login extends Vue {
	user = {
		username: '',
		password: '',
	}

	mounted(){
		Global.clear()
	}

	async authenticate(){
		if (!(this.user.username && this.user.password)) {
			const inputs = this.$el.querySelectorAll('input')
			inputs.forEach(input => {input.focus(); input.blur()})
			inputs[0].focus()
			return
		}
		try {
			await Global.authenticate(this.user)
			this.$router.push({ name: "Main" })
		} catch(e){
			this.$buefy.notification.open({
				duration: 1000,
				message: 'Usuario o Contraseña incorrecto',
				position: 'is-top',
				type: 'is-danger',
				hasIcon: true,
			})
		}

	}
}
