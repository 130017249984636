import { createModule, mutation, action } from 'vuex-class-component'
import axios from 'axios'

const VuexModule = createModule({
	namespaced: 'Global',
	strict: false,
})

export default class GlobalStore extends VuexModule {
	isAuthenticated = false
	loading = false
	authenticating = true
	user: any = {}

	@mutation
	setAuthenticating(value: boolean){
		this.authenticating = value
	}

	@mutation
	setIsAuthenticated(value: boolean){
		this.isAuthenticated = value
	}

	@mutation
	setUser(value: any){
		this.user = value
	}

	@action
	async authenticate(creds?: any) {
		const params = new URLSearchParams(creds)
		const { data } = await axios.post(`/api/v1/login/external`, params)
		this.setUser(data.user)
		localStorage.user = JSON.stringify(data.user)
		localStorage.token = JSON.stringify(data.token.access_token)
		this.setAuthenticating(false)
		this.setIsAuthenticated(true)
	}

	@action
	async clear(){
		this.setUser({})
		this.setIsAuthenticated(false)
		localStorage.clear()
	}

	@action
	async logout(){
		this.setUser({})
	}
}
