import { Base, Model, Primed } from 'primed-model'
import { PrimedDate, PrimedDateTime, PrimedDecimal, PrimedId, PrimedUUID } from './primed-types'

import { ARGUMENT_DETAILS } from './background_check/bgc-static';
import { DateTime } from 'luxon'

@Model('BackgroundCheck')
export class BackgroundCheck extends Base<BackgroundCheck>{
	id = ''
	status = ''

	@Primed(PrimedDateTime)
	created_at!: DateTime

	@Primed('Details')
	details!: Details

	@Primed('Person')
	person!: Person

	get formattedCreatedAt(){
		return this.created_at.toFormat('d LLL, t')
	}
}


@Model('Person')
export class Person extends Base<Person>{
	id = ''
	first_name = ''
	middle_name = ''
	last_name = ''
	second_last_name = ''
	national_id = ''
	gender = ''

	@Primed(PrimedDateTime)
	dob!: DateTime

	get dob_short(){
		return this.dob.toFormat('dd/MM/yy')
	}

	get display_name(){
		return [this.first_name, this.middle_name, this.last_name, this.second_last_name].filter(x => x).join(' ')
	}

	get names(){
		return [this.first_name, this.middle_name].filter(x => x).join(' ')
	}

	get last_names(){
		return [this.last_name, this.second_last_name].filter(x => x).join(' ')
	}
}


@Model('Details')
export class Details extends Base<Details>{
	screening_done = true

	@Primed('JUD_PENAL')
	JUD_PENAL!: JUD_PENAL
}


@Model('JUD_PENAL')
export class JUD_PENAL extends Base<JUD_PENAL>{
	@Primed('Case', { array: true, required: false })
	cases!: Case[]

	addNewCase(){
		this.cases.push(new Case())
	}

	clearCases(){
		this.cases = []
	}
}


@Model('Case')
export class Case extends Base<Case>{
	estado_actual: string | null = null
	no_ficha: string | null = null
	tipo_sentencia: string | null = null
	razon: string | null = null
	#internalDetails: string | null = null
	deported = false

	@Primed(PrimedDateTime, { required: false })
	fecha_sentencia!: DateTime

	@Primed(PrimedDateTime, { required: false })
	fecha_ingreso_prision!: DateTime

	get details(){
		if(this.#internalDetails) return this.#internalDetails

		const razon = this.razon || '[razon]'
		const tipoSentencia = this.tipo_sentencia || '[tipo sentencia]'
		const estadoActual = this.estado_actual || '[estado actual]'
		const noFicha = this.no_ficha || '[ficha]'

		const format = (str: string, ...args: any[]) => str.replace(/\{(\d+)\}/g, (s, d) => args[d])

		return format(ARGUMENT_DETAILS, razon, tipoSentencia, estadoActual, noFicha)
	}

	set details(details: string){
		this.#internalDetails = details
	}
}
