
import { Vue, Component } from 'vue-property-decorator'
import { Global } from './store'


@Component({
})
export default class App extends Vue{
	created(){
		const { user } = localStorage
		if(user) {
			Global.setUser(JSON.parse(user))
			Global.setIsAuthenticated(true)
		}
	}
}
